@import "./assets/styles/general";

.App-container {
  overflow: hidden;

  &-content {
    display: flex;
    height: calc(100vh - 80px);

    &-body {
      flex: 1;
      background-color: $offWhite;
      overflow-y: auto;
    }
  }
}

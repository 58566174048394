$offBlack: #14142b;
$ash: #263338;
$body: #4e4b66;
$label: #6d819b;
$placeholder: #a0a3bd;
$line: #d9dbe9;

$input: #f2f6fe;
$inputText: #1f1b56;

$background: #f7f7fc;
$offWhite: #fcfcfc;

$primaryColor: #002a48;
$secondaryColor: #e5f8ff;

$sky100: #e5ebfe;
$sky200: #9bb2fd;
$sky800: #032596;

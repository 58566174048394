@import "./general";

// ---------- BOOTSTRAP OVERRIDES ----------

$font-family-sans-serif: "Poppins", sans-serif;

$input-btn-padding-x: 1.3rem;

$input-bg: rgba(225, 227, 233, 0.1);
$input-border-color: #d3dce6;
$input-placeholder-color: rgba(82, 87, 92, 0.4);

$hr-color: #d3dce6;
$hr-opacity: 1;
$hr-height: 0.5px;

$btn-border-radius: 3px;
$btn-font-weight: 600;
$btn-font-size: 15px;
$btn-line-height: 24px;

$dropdown-link-hover-bg: #5382ff29;

$primary: $primaryColor;
$secondary: #adbad5;
$success: #009977;
$warning: #cc5e00;
$danger: #990000;

@import "~bootstrap/scss/bootstrap";

// -- Desktop styles --

@mixin desktop-display-huge {
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 1px;
}

@mixin desktop-display-large {
  font-size: 50px;
  line-height: 64px;
  letter-spacing: 1px;
}

@mixin desktop-display-medium {
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 1px;
}

@mixin desktop-display-small {
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 1px;
}

@mixin font-display-xsmall {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1px;
}

@mixin desktop-text-large {
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0.75px;
}

@mixin desktop-text-medium {
  font-size: 17px;
  line-height: 34px;
  letter-spacing: 0.75px;
}

@mixin desktop-text-small {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
}

@mixin desktop-text-xsmall {
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.75px;
}

@mixin desktop-link-large {
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0.75px;
}

@mixin desktop-link-medium {
  font-size: 17px;
  line-height: 34px;
  letter-spacing: 0.75px;
}

@mixin desktop-link-small {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.75px;
}

@mixin desktop-link-xsmall {
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
}

// -------- FONT FAMILIES ----------

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url(../fonts/Poppins/Poppins-ThinItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/Poppins/Poppins-LightItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url(../fonts/Poppins/Poppins-BoldItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url(../fonts/Poppins/Poppins-BlackItalic.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Poppins/Poppins-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Poppins/Poppins-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Poppins/Poppins-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Poppins/Poppins-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Poppins/Poppins-Black.ttf) format('opentype');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Oswald/Oswald-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Roboto/Roboto-Regular.ttf) format('opentype');
}

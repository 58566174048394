@import '../../../../assets/styles/general';

.LoginPage {
  display: flex;
  flex-direction: column;
  height: calc(100% - 200px);
  margin: 100px 0;

  .forgot-your-password {
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: normal;
    color: $primaryColor;
    border: none;
    background: none;
  }
}

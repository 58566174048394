@import './colors';
@import './fonts';
@import './media-queries';
@import './mixins';

.cursor-pointer {
  cursor: pointer;
}

.center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.MuiTab-root {
  font-family: 'Poppins' !important;
}

.MuiAvatar-colorDefault {
  background-color: $line !important;
}

.flex-1 {
  flex: 1;
}

@import '../../assets/styles/general';

.AccessPage {
  width: 100vw;
  height: 100vh;
  // background-image: url('https://sbg-public-files.s3.us-east-2.amazonaws.com/background02.jpeg');
  background-repeat: no-repeat;
  background-size: cover;

  &-content {
    background-color: #dddfdf;
  }

  .QCInput-container input {
    font-weight: 600;
    color: $inputText;
  }

  .form-container {
    width: 595px;
    display: flex;
    align-items: center;
    border-radius: 0 60px 0 0;
    background-color: white;

    .content {
      width: 100%;
      margin: 100px;
      height: 100%;

      .form-title {
        font-size: 28px;
        font-weight: bold;
        line-height: 1.14;
        color: $primaryColor;
      }

      .register-as {
        font-family: ProximaNova;
        font-weight: 600;
        line-height: 1.6;
        color: $inputText;
        background: none;
        border: none;
      }
    }
  }

  .aside-block {
    flex: 1.5;
    padding: 5rem 6rem;
    @include desktop-text-medium;

    &-title {
      background-color: #ffffff66;
      padding: 30px 40px;
      border-radius: 5px;
      font-family: 'Oswald';
      letter-spacing: 1px;

      p {
        text-align: justify;
        font-weight: 300;
      }
    }

    &-subtitle {
      @include desktop-display-huge;
      font-size: 60px;
      font-weight: 800;
      font-family: 'Oswald';
    }

    img {
      width: 60%;
    }
  }
}
